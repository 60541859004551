import React, { useState } from "react";
import styles from "./index.module.css";
import Spacer from "./spacer";
import AutoGenSlides from "./assets/media/documents/autogen-slides.pdf";
import IntroToAgents from "./assets/media/documents/intro-to-agents.pdf";
import IntroToLLMS from "./assets/media/documents/intro-to-llms.pdf";
import CDI from "./assets/media/documents/ai-conversational-data-intelligence.pdf";
import ArduinoSlides from './assets/media/documents/arduino-slides.pdf'
import SXSWSlides from './assets/media/documents/sxsw-slides.pdf'
import GitHubRepoCard from "./github-card";
import BondAILogo from './assets/open-source/bondai-logo.png'
import ArduinoLogo from './assets/open-source/arduino.png'
import PusherLogo from './assets/open-source/pusher.png'
import AlexaLogo from './assets/open-source/alexa.png'
import DollyLogo from './assets/open-source/dolly.png'
import AlpacaLogo from './assets/open-source/alpaca.png'

// Blog API
// https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@krohling

const Home = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (sectionId) => {
        setMenuOpen(false); // Close the menu
    
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);

        // setTimeout(() => {
        //     const section = document.getElementById(sectionId);
        //     if (section) {
        //         const topOffset = section.getBoundingClientRect().top + window.pageYOffset;
        //         window.scrollTo({
        //             top: topOffset,
        //             behavior: 'smooth'
        //         });
        //     }
        // }, 300);
    };

    return <div>
            <div className={`${styles.hamburgerIcon} ${styles.mobile} ${isMenuOpen ? styles.showMenu : ''}`} onClick={toggleMenu}>
                <span/>
                <span/>
                <span/>
            </div>

            <div className={`${styles.mobileMenu} ${isMenuOpen ? styles.showMenu : ''} ${styles.mobile}`}>
                <button className={styles.closeButton} onClick={toggleMenu}>
                    <span>&times;</span>
                </button>
                <button onClick={() => scrollToSection('homeSection')}>Home</button>
                <a href="https://medium.com/@krohling" target="_blank" rel="noopener noreferrer">Blog</a>
                <button onClick={() => scrollToSection('skillsSection')}>Skills</button>
                <button onClick={() => scrollToSection('recentWorkSection')}>Recent Work</button>
                <button onClick={() => scrollToSection('openSourceSection')}>Open Source</button>
                <button onClick={() => scrollToSection('educationSection')}>Education</button>
                <button onClick={() => scrollToSection('mediaSection')}>Media</button>
            </div>

            <div className={`${styles.desktopMenu} ${styles.desktop}`}>
                <button onClick={() => scrollToSection('homeSection')}>Home</button>
                <a href="https://medium.com/@krohling" target="_blank" rel="noopener noreferrer">Blog</a>
                <button onClick={() => scrollToSection('skillsSection')}>Skills</button>
                <button onClick={() => scrollToSection('recentWorkSection')}>Recent Work</button>
                <button onClick={() => scrollToSection('openSourceSection')}>Open Source</button>
                <button onClick={() => scrollToSection('educationSection')}>Education</button>
                <button onClick={() => scrollToSection('mediaSection')}>Media</button>
            </div>

            <div id="homeSection" className={styles.hero}>
                <div className={styles.headline}>
                    <div className={styles.profileImage}/>
                    <div className={styles.headlineText}>
                        <h1>Kevin Rohling</h1>
                        <h2>I love building things people love (with AI)</h2>
                        <div className={styles.socialIcons}>
                            <a href="https://www.linkedin.com/in/krohling" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.linkedin}`}/>
                            </a>
                            <a href="https://github.com/krohling" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.github}`}/>
                            </a>
                            <a href="mailto:kevin@kevinrohling.com" target="_blank" rel="noopener noreferrer">
                                <div className={`${styles.socialIcon} ${styles.email}`}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="skillsSection" className={styles.section}>
                <div className={styles.sectionTitle}>Skills</div>
                <div className={styles.skillsGrid}>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.ai}`}>
                        AI Engineering
                    </div>
                    <div className={`${styles.skillsItem} ${styles.pytorch}`}/>
                    <div className={`${styles.skillsItem} ${styles.computerVision}`}>
                        <div className={styles.label}>
                            Computer Vision
                        </div>
                    </div>
                    <div className={`${styles.skillsItem} ${styles.nlp}`}>
                        <div className={styles.label}>
                            Natural Language Processing
                        </div>
                    </div>
                    <div className={`${styles.skillsItem} ${styles.python}`}/>
                    <div className={`${styles.skillsItem} ${styles.react}`}/>
                    <div className={`${styles.skillsItem} ${styles.nodejs}`}/>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.software}`}>
                        Software Engineering
                    </div>
                    <div className={`${styles.skillsItem} ${styles.title} ${styles.other}`}>
                        Certifications
                    </div>
                    <div className={`${styles.skillsItem} ${styles.aws}`}/>
                    <div className={`${styles.skillsItem} ${styles.scrum}`}/>
                    <div className={`${styles.skillsItem} ${styles.crossfit}`}/>
                </div>
            </div>
            <div id="recentWorkSection" className={styles.section}>
                <div className={styles.sectionTitle}>Recent Work</div>
                <div className={styles.sectionItemList}>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.presence}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Presence Product Group
                            </div>
                            <div className={styles.description}>
                                Head of Artificial Intelligence<br/>
                                <i>2023 - Present</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.presence}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Presence Product Group
                            </div>
                            <div className={styles.description}>
                                Sr. Engineering Lead<br/>
                                <i>2020 - 2023</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.testai}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Test.AI
                            </div>
                            <div className={styles.description}>
                                VP Engineering<br/>
                                <i>2019 - 2020</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.booknook}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                BookNook Learning
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/CTO<br/>
                                <i>2016 - 2019</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.emberlight}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Emberlight
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/VP Engineering<br/>
                                <i>2014 - 2015</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.cisimple}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                cisimple
                            </div>
                            <div className={styles.description}>
                                Founding Engineer/CEO<br/>
                                <i>2012 - 2014</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    {/* 
                    <div className={`${styles.workItem} ${styles.breezy}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Breezy
                            </div>
                            <div className={styles.description}>
                                Founding CTO<br/>
                                <i>2011 - 2012</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.vertigo}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Verito Software
                            </div>
                            <div className={styles.description}>
                                Sr Software Engineer<br/>
                                <i>2009 - 2011</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.disney}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                The Walt Disney Company
                            </div>
                            <div className={styles.description}>
                                Software Engineer<br/>
                                <i>2009 - 2011</i>
                            </div>
                        </div>
                    </div>
                    <Spacer /> */}
                </div>
            </div>
            <div id="openSourceSection" className={styles.section}>
                <div className={styles.sectionTitle}>Open Source</div>
                <div className={styles.sectionItemList}>
                    <GitHubRepoCard 
                        name="BondAI"
                        logo={BondAILogo}
                        repoUrl="https://api.github.com/repos/krohling/bondai"
                        repoHtmlUrl="https://github.com/krohling/bondai"
                        description="BondAI is an open-source framework for developing AI Agent Systems. BondAI handles tool execution, memory/context management, semantic search and includes a powerful set of out of the box tools & integrations."
                        />
                    <GitHubRepoCard 
                        name="Finetuned Flan UL2 Dolly"
                        logo={DollyLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/flan-ul2-dolly"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/flan-ul2-dolly"
                        description="This repository contains code for using the Dolly 15K dataset released by Databricks to fine tune the Flan-UL2 model for instruction following."
                        />
                    <GitHubRepoCard 
                        name="Finetuned Flan UL2 Alpaca"
                        logo={AlpacaLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/flan-ul2-alpaca"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/flan-ul2-alpaca"
                        description="This repository contains code for using the Stanford Alpaca synthetic dataset to fine tune the Flan-UL2 model for instruction following."
                        />
                    <GitHubRepoCard 
                        name="Arduino Websocket Client"
                        logo={ArduinoLogo}
                        repoUrl="https://api.github.com/repos/krohling/ArduinoWebsocketClient"
                        repoHtmlUrl="https://github.com/krohling/ArduinoWebsocketClient"
                        description="Websockets are a simple and lightweight way to send and receive messages from web browsers. This project was developed to extend this capability to embedded devices (Arduinos)."
                        />
                    <GitHubRepoCard 
                        name="Arduino Pusher Client"
                        logo={PusherLogo}
                        repoUrl="https://api.github.com/repos/krohling/ArduinoPusherClient"
                        repoHtmlUrl="https://github.com/krohling/ArduinoPusherClient"
                        description="Pusher is a hosted Push Notification service that uses Websockets for relaying messages back and forth between clients. This library extends this functionality to Arduino devices."
                        />
                    <GitHubRepoCard 
                        name="Chat GPT Alexa Skill"
                        logo={AlexaLogo}
                        repoUrl="https://api.github.com/repos/ConiferLabsWA/molly-gpt-alexa-skill"
                        repoHtmlUrl="https://github.com/ConiferLabsWA/molly-gpt-alexa-skill"
                        description="This repository contains code for an Alexa skill that uses OpenAI models to conversationally respond to user input."
                        />
                </div>
            </div>
            <div id="educationSection" className={styles.section}>
                <div className={styles.sectionTitle}>Education</div>
                <div className={styles.sectionItemList}>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.uttexas}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of Texas at Austin
                            </div>
                            <div className={styles.description}>
                                MS Artificial Intelligence<br/>
                                <i>In Progress</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.ucf}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of Central Florida
                            </div>
                            <div className={styles.description}>
                                BS Computer Science<br/>
                                <i>2008</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.scrum}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                Scrum.org
                            </div>
                            <div className={styles.description}>
                                Professional Scrum Master I<br/>
                                <i>2023</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.crossfit}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                CrossFit, LLC
                            </div>
                            <div className={styles.description}>
                                CrossFit Level 1<br/>
                                <i>2021</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.usf}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                University of San Francisco
                            </div>
                            <div className={styles.description}>
                                Deep Learning - Part I<br/>
                                <i>2018</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.edx}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                EdX
                            </div>
                            <div className={styles.description}>
                                Embedded Systems<br/>
                                <i>2014</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    <div className={`${styles.workItem} ${styles.edx}`}>
                        <div className={styles.logo} />
                        <div className={`${styles.details}`}>
                            <div className={styles.title}>
                                EdX
                            </div>
                            <div className={styles.description}>
                                Quantum Mechanics and Quantum Computation<br/>
                                <i>2013</i>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                </div>
            </div>
            <div id="mediaSection" className={styles.section}>
                <div className={styles.sectionTitle}>Media</div>
                <div className={styles.sectionItemList}>
                    <a href="https://blog.dragosbogdan.net/p/ai-in-2023-and-beyond-interview-w?utm_campaign=post&utm_medium=web" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.video} ${styles.aiinterview}`}>
                            <div className={styles.cover}>
                                Dragos Bogdan Interview
                            </div>
                        </div>
                    </a>
                    <a href={IntroToLLMS} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.llms}`}/>
                    </a>
                    <a href="https://www.youtube.com/watch?v=AiMIDUbeJZk" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.video} ${styles.llmsvideo}`}>
                            <div className={styles.cover}>
                                Introduction to LLMs
                            </div>
                        </div>
                    </a>
                    <a href={AutoGenSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.autoGen}`}/>
                    </a>
                    <a href={IntroToAgents} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.agents}`}/>
                    </a>
                    <a href={CDI} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.document} ${styles.cdi}`}/>
                    </a>
                    <a href={ArduinoSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.arduino}`}/>
                    </a>
                    <a href={SXSWSlides} target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.mediaItem} ${styles.slideDeck} ${styles.sxsw}`}/>
                    </a>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.socialIcons}>
                    <a href="https://www.linkedin.com/in/krohling" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.linkedin}`}/>
                    </a>
                    <a href="https://github.com/krohling" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.github}`}/>
                    </a>
                    <a href="mailto:kevin@kevinrohling.com" target="_blank" rel="noopener noreferrer">
                        <div className={`${styles.socialIcon} ${styles.email}`}/>
                    </a>
                </div>
            </div>
        </div>
}

export default Home;
import React, { useState, useEffect } from 'react';
import styles from './index.module.css';

const GitHubRepoCard = ({ repoUrl, repoHtmlUrl, logo, name, description }) => {
    const [repoData, setRepoData] = useState(null);

    useEffect(() => {
        fetch(repoUrl)
            .then(response => response.json())
            .then(data => setRepoData(data))
            .catch(error => console.error('Error fetching data: ', error));
    }, [repoUrl]);

    return (
        <a href={(repoData && repoData.html_url) || repoHtmlUrl} target="_blank" rel="noopener noreferrer">
            <div className={`${styles.root}`}>
                <div className={styles.logo} style={{ backgroundImage: `url(${logo})` }}/>
                    {
                        repoData && (
                            <div className={styles.details}>
                                <div className={styles.title}>
                                    {name || (repoData && repoData.name) || 'Loading...'}
                                </div>
                                <div className={`${styles.stats} ${styles.stars}`}>
                                    {repoData.stargazers_count}
                                </div>
                                <div className={`${styles.stats} ${styles.forks}`}>
                                    {repoData.forks_count}
                                </div>
                                <div className={`${styles.stats}`}>
                                    {repoData.language}
                                </div>
                                <div className={styles.description}>
                                    {description || repoData.description}
                                </div>
                            </div>
                        )
                    }
            </div>
        </a>
    );
};

export default GitHubRepoCard;
